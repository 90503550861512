import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/attack-surface-reduction/",
    label: "ATTACK SURFACE REDUCTION ",
  },
]

const Attack = () => (
  <Layout>
    <Seo title="Attack Surface Reduction" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
    <section className="section-title-top--mdr-services grid--bg --for-it-ops-1">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h1>
              ATTACK SURFACE <br />
              REDUCTION
            </h1>
            <p className="content">
              The Art of Cyber-War is best fought from a position of strength
              behind an impenetrable defense. Vigilant provides processes and
              technologies to help ensure that each company inhabits the “high
              ground,” a place free of vulnerabilities and weaknesses a threat
              actor would look to exploit.
            </p>
            <p className="content">
              Those technologies include – but are not limited to – VigilantMVDR
              and Penetration Testing.
            </p>
            <Link to="/contact/" className="nav-section--cta-btn">
              <div className="d-flex align-items-center">
                <span
                  style={{ marginLeft: "25px" }}
                  className="nav-section--cta-title pr-5"
                >
                  reduce risk today
                </span>
                <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
              </div>
            </Link>
          </div>
          <div className="section-col-2"></div>
        </div>
      </div>
    </section>

    <section className="section-slider">
      <div className="section-col-slide">
        <div className="section-col-1">
          <h3>VigilantMVDR</h3>

          <a
            href="https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf"
            className="nav-section--cta-btn"
            target="__blank"
          >
            <div className="d-flex align-items-center">
              <span
                style={{ marginLeft: "25px" }}
                className="nav-section--cta-title pr-5"
              >
                learn more
              </span>
              <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
            </div>
          </a>
        </div>
        <div className="section-col-2">
          <p className="content">
            Vigilant Managed Vulnerability Detection and Response is a security
            service that strengthens an organization’s cyber defense, allowing
            it to get ahead of any threat. Utilizing Vigilant’s Adaptive
            Intelligence Process™, a full and continuous scanning and analysis
            process susses out and identifies any potential vulnerabilities…
            resulting in preventative remediation.
          </p>
          <p className="content">
            Preventative security is the key. Vigilant believes that less threat
            management is better threat management. And less is only possible by
            removing vulnerabilities thus reducing the overall potential attack
            surface of any organization. This is why Vigilant provides an
            Unlimited Breach Response Warranty. We provide the preventative work
            up front and as a result, we limit the number of attack incidents
            our clients must endure in the future.{" "}
          </p>
        </div>
      </div>

      <div className="section-col-slide">
        <div className="section-col-1">
          <h3>PENETRATION TESTING</h3>
          <a
            href="https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf"
            className="nav-section--cta-btn"
            target="__blank"
          >
            <div className="d-flex align-items-center">
              <span
                style={{ marginLeft: "25px" }}
                className="nav-section--cta-title pr-5"
              >
                learn more
              </span>
              <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
            </div>
          </a>
        </div>
        <div className="section-col-2">
          <p className="content">
            Penetration Testing is preventative defense through cyber-attack
            simulation. When the goal is securing the higher ground, there is no
            substitute for real-world drills. That’s why Vigilant Penetration
            Testing allows ethical hackers to simulate a cyber-attack against
            your systems, applications, or entire infrastructure. We don’t stop
            at the technical side – we also test against the people and process
            side, utilizing the same tools and techniques.
          </p>
          <p className="content">
            Vigilant Penetration Testing goes well beyond what a mere automatic
            scan can accomplish. We identify how a hacker might target you and
            the potential methods used during an attack. Our Penetration Testing
            enables organizations to see just how their defensive tooling would
            stand up to an attack and the possible risk of a breach. This work
            is not for the faint of heart – but absolutely necessary today.{" "}
          </p>
        </div>
      </div>
    </section>
    <section className="section-client-quote">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <p>
                “The art of war teaches us to rely not on the likelihood of the
                enemy not coming, but on our own readiness to receive him; not
                on the chance of his not attacking, but rather on the fact that
                we have made our position unassailable.”
              </p>
              <hr />
              <h6>- THE ART OF WAR</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section-client-quote">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <div className="section-col-1--content">
              <h3>TRY VIGILIANT FOR YOURSELF</h3>
              <hr />
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started today
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default Attack
